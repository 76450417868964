<template>
  <div class="BrandInt">
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">

          <div class="swiper-slide">
                        <img src="https://qiniu.youjiamall.cn/yjpc/banner4.png" alt="">
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="about_us">
      <subTitle zh-tit="关于我们" en-tit="ABOUT US"></subTitle>
      <div class="ab_top">
        <div class="ab_left">
          <div>
            <p class="ab_tit">北京优加惠品科技服务有限公司</p>
            <p style="margin: 57px 0 38px;line-height: 32px;">优加惠品，专注于为企业员工提供多元化定制福利产品及优质服务。
              我们整合众多优质资源，量身定制福利服务方案，更大限度满足不同员工的日常需求，让企业员工在使用体验达到更佳。</p>
            <p style="line-height: 32px;">我们深知员工福利对于企业文化建设和员工关系的重要性，因此我们整合众多优质资源、服务团队和专业研发团队，力求让每一位员工在使用过程中感受到关怀与安心。我们相信只有真正关注员工的需求和感受，才能让企业和员工共同成长、繁荣。</p>
          </div>

        </div>
        <ul class="ab_right">
        </ul>
      </div>


<!--      我们的服务-->
      <pubBoxTit title="我们的服务" ltit="SERVICE"></pubBoxTit>
      <div class="we_ser">
        <ul>
          <li>
            <div>
              <p>福利策划</p>
              <span>
                福利体系策划设计<br>
                产品组合专属策划<br>
                发放仪式道具定制<br>
                公司主题活动设计
              </span>
            </div>
          </li>
          <li>
            <div>
              <p>个性化定制</p>
              <span>
                产品组合定制<br>
                产品图样定制<br>
                通兑权限定制<br>
                弹性系统定制
              </span>
            </div>
          </li>
          <li>
            <div>



              <p>完整的售后</p>
              <span>
                质量问题先行赔付<br>
                长期有效免费续期<br>
                7*12小时真实客服<br>
                售后无忧
              </span>
            </div>
          </li>
        </ul>
      </div>

      <!--      我们的优势-->
      <pubBoxTit title="我们的优势" ltit="SUPERIORITY"></pubBoxTit>
      <ul class="we_good">
        <li>
          <p class="img_box">
            <img src="https://qiniu.youjiamall.cn/yjpc/ser_icon_1.png" alt="">
          </p>
          <div>
            <p>创意</p>
            <span>十余年专业策划团队<br>人文化细节关怀<br>情意为魂做产品设计</span>
          </div>
        </li>
        <li>
          <p class="img_box">
            <img src="https://qiniu.youjiamall.cn/yjpc/ser_icon_2.png" alt="">
          </p>
          <div>
            <p>组织</p>
            <span>构建了10W+产品资源库<br>为客户量身定制福利活动方案<br>策划/组织/专业人员协助</span>
          </div>
        </li>
        <li>
          <p class="img_box">
            <img src="https://qiniu.youjiamall.cn/yjpc/ser_icon_3.png" alt="">
          </p>
          <div>
            <p>传递</p>
            <span>套餐式发放仪式让人更感动<br>产品及包装细节让体验更动情<br>礼堆/宅配/礼包券等多种方式可选</span>
          </div>
        </li>
        <li>
          <p class="img_box">
            <img src="https://qiniu.youjiamall.cn/yjpc/ser_icon_4.png" alt="">
          </p>
          <div>
            <p>呈现</p>
            <span>包装设计让福利更有价值<br>贴心设计让礼包更有温情</span>
          </div>
        </li>
        <li>
          <p class="img_box">
            <img src="https://qiniu.youjiamall.cn/yjpc/ser_icon_5.png" alt="">
          </p>
          <div>
            <p>使用</p>
            <span>暖心设计让使用更轻松<br>多平台让服务于沟通更便捷</span>
          </div>
        </li>
        <li>
          <p class="img_box">
            <img src="https://qiniu.youjiamall.cn/yjpc/ser_icon_6.png" alt="">
          </p>
          <div>
            <p>贴心</p>
            <span>专业团队<br>售后无忧</span>
          </div>
        </li>
      </ul>

<!--      我们的使命-->
      <ul class="we_sm">
        <li>
          <img src="https://qiniu.youjiamall.cn/yjpc/ser_b_icon3.png" alt="">
          <p>我们的愿景</p>
          <span>为企业传递关爱</span>
        </li>
        <li>
          <img src="https://qiniu.youjiamall.cn/yjpc/ser_b_icon1.png" alt="">
          <p>我们的使命</p>
          <span>为员工营造幸福</span>
        </li>

        <li style="padding-top: 12px">
          <img src="https://qiniu.youjiamall.cn/yjpc/ser_b_icon2.png" alt="">
          <p>我们的价值观</p>
          <span>为和谐创造价值</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import subTitle from "@/components/subTitle";
import pubBoxTit from "@/components/pubBoxTit";
export default {
  name: "BrandInt",
  components:{subTitle,pubBoxTit},
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
}
</script>

<style scoped>
  .about_us {
    margin-top: 60px;
  }
  .ab_top {
    display: flex;
    justify-content: space-between;
    padding-right: 189px;
    box-sizing: border-box;
    padding-top: 70px;
  }
  .ab_left {
    width: 850px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    padding-left: 138px;
    padding-bottom: 70px;
    box-sizing: border-box;
  }

  .ab_left .ab_tit {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .ab_right {
    /*position: relative;*/
    /*width:50%;*/
    width: 335px;
    /*height: 320px;*/
    background: url("https://qiniu.youjiamall.cn/yjpc/about_bg.png")no-repeat 80% 20% ;
    background-size: 80%;
  }
  .ab_right li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    font-family: Arial Black;
    font-weight: 400;
    color: #007858;
    position: absolute;
  }
  .ab_right li:nth-child(1) {
    left: 120px;
    top: 31px;
    width: 260px;
    height: 260px;
    background: #007858;
    /*background: rgba(0, 120, 88, .1);*/
    border-radius: 50%;
    color: #FFFFFF;
  }

  .ab_right li p{
    margin-bottom: 5px;
  }
  .ab_right li span {
    font-size: 18px;
    font-family: PingFang SC;
  }
  .ab_right li:nth-child(1) p{
    font-size: 43px;
    font-family: Arial Black;
    font-weight: 400;
  }
  .ab_right li:nth-child(1) span{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
  }
  .ab_right li:nth-child(1) {
    width: 260px;
    height: 260px;
    background: #007858;
    border-radius: 50%;
    color: #FFFFFF;
  }
  .ab_right li:nth-child(2) {
    left: 335px;
    width: 205px;
    height: 209px;
    background: rgba(0, 120, 88, .1);
    border-radius: 50%;
  }
  .ab_right li:nth-child(2) span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
  }
  .ab_right li:nth-child(3) {
    left: 310px;
    top: 178px;
    width: 190px;
    height: 190px;
    background: #FFF100;
    border-radius: 50%;
  }

  .we_ser {
    padding: 60px 120px;
    box-sizing: border-box;
  }
  .we_ser ul {
    display: flex;
    justify-content: space-between;
  }
  .we_ser li {
    padding: 70px 0 0 20px;
    box-sizing: border-box;
    width: 385px;
    height: 270px;
    background: url("https://qiniu.youjiamall.cn/yjpc/ser_bg1.png")no-repeat;
    background-size: 100% auto;
  }
  .we_ser li:nth-child(2) {
    background: url("https://qiniu.youjiamall.cn/yjpc/ser_bg2.png")no-repeat;
    background-size: 100% auto;
  }
  .we_ser li:nth-child(3) {
    background: url("https://qiniu.youjiamall.cn/yjpc/ser_bg3.png")no-repeat;
    background-size: 100% auto;
  }
  .we_ser li div {
    width: 175px;
    height: 201px;
    background: #007858;
    border-radius: 18px 18px 0 0;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /*padding: 0 33px;*/
    padding-top: 30px;
    box-sizing: border-box;
    background: linear-gradient(to bottom,#007858,rgba(0, 120, 88, .6));
  }
  .we_ser li p {
    font-size: 18px;
    margin-bottom: 22px;
  }
  .we_ser span {
    line-height: 26px;
  }
  .we_good {
    padding: 55px 120px 66px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .we_good div p {
    margin-bottom: 16px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .we_good li {
    margin-bottom: 21px;
    width: 386px;
    height: 180px;
    background: #FFFFFF;
    border: 1.2px solid #eee;
    /*box-shadow: 0px 8px 14px 0px rgba(189,189,189,0.35);*/
    display: flex;
    align-items: center;
    padding: 0 25px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .we_good li span {
    line-height: 24px;
  }
  .we_good li:hover {
    border: 0 !important;
    box-shadow: 0px 5px 22px 0px rgba(0,0,0,0.1);
  }
  .we_good .img_box {
    width: 88px;
    height: 88px;
    background: #FBFBFB;
    border-radius: 50%;
    text-align: center;
    line-height: 88px;
    display: flex;
    margin-right: 26px;
  }
  .img_box img{
    margin: auto;
  }


  .we_sm {
    width: 100%;
    background: url("https://qiniu.youjiamall.cn/yjpc/about_btm_bg.png")no-repeat;
    background-size: 100% auto;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 271px;
    box-sizing: border-box;
    text-align: center;
  }
  .we_sm p{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 29px 0 16px;
    color: #FFEBB3;
  }
  .we_sm span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFEBB3;
  }
</style>
